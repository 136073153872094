import React from "react";

import Layout from "../../components/layout/Layout";
import Heading from "../../components/ui/Heading";
import ResponsiveContainer from "../../components/ui/ResponsiveContainer";

import * as styles from "./styles.module.scss";

const Conditions = () => {
  return (
    <Layout title='Teilnahmebedingungen'>
      <ResponsiveContainer>
        <div className={styles.container}>
          <p>
            <strong className={styles.heading}>Teilnahmebedingungen Gewinnspiel</strong>
            <br />
            <br />
            Veranstalter des Gewinnspiels ist die Wertschätzungsinitiative #dubistmehrwert. Mit der
            Teilnahme am Gewinnspiel akzeptiert die/der Teilnehmer*in diese Teilnahmebedingungen.
            <br />
            <br />
            Teilnahmeberechtigt sind alle Postbediensteten, Angehörige sowie Unterstützer*innen der
            Initiative. Pro Person ist nur eine Teilnahme möglich.
            <br />
            <br />
            Teilnahmeschluss ist der 22.12.2022. Der Gewinn ist nicht übertragbar oder austauschbar
            und kann nicht in bar abgelöst werden. Die Ermittlung des Gewinners erfolgt unter
            Ausschluss der Öffentlichkeit. Die Gewinner werden schriftlich per E-Mail an die
            angegebene E-Mail-Adresse verständigt. Alle mit dem Gewinnspiel und dem Gewinn
            verbundenen Steuern, Abgaben und Gebühren tragen die Gewinner. Es bestehen weder
            Gewährleistungs- noch Garantieanspruch.
            <br />
            <br />
            Jede/r Teilnehmer*in erklärt sich damit einverstanden, dass seine Daten (Vorname und
            Nachname) auf der Homepage der Wertschätzungsinitiative #dubistmehrwert veröffentlicht
            werden können. Diese Einverständniserklärung kann per E-Mail: info@dubistmehrwert.at
            jederzeit widerrufen werden.
          </p>
        </div>
      </ResponsiveContainer>
    </Layout>
  );
};

export default Conditions;
